import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "into-the-ocean",
      "style": {
        "position": "relative"
      }
    }}>{`Into The Ocean`}<a parentName="h1" {...{
        "href": "#into-the-ocean",
        "aria-label": "into the ocean permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`In our first session, we will create a plan for future weekly sessions and then go through the process of publishing a dataset/algorithm on testnet. To start, we will use UI tools with no code, so this tutorial is suitable for non-technical people interested in data science/AI on Web3.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul">{`Watch Trent McConaghy speak about `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=sKbVieQ_foo"
        }}>{`decentralized data economies`}</a></li>
    </ul>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/AThhcQrjRQk" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      